.mat-button-gradient{
    background-image: linear-gradient(to right, #e25618 , #e49326);
    color: whitesmoke;
    margin-bottom: 2% !important;
}
.mat-button-gradient-green{
    background-image: linear-gradient(to right, #459332 , #afb71a);
    color: whitesmoke;
    margin-bottom: 2% !important;
}
.mat-icon-delete{
    color:#EC7063;
    float: right;
}
.float-left{
    float: left !important;
}
.float-right{
    float: right !important;
}